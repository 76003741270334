import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { round } from 'mathjs';
import { either, is, isEmpty, isNil, or, reject, toString } from 'ramda';

export const NO_VALUE_PLACEHOLDER = '-';

export const isNilOrPlaceholder = (value) =>
  or(isNil(value), value === NO_VALUE_PLACEHOLDER);

const isNumber = is(Number);

export const formatCurrency = (value, currencyCode) => {
  if (
    isNilOrPlaceholder(value) ||
    !isNumber(value) ||
    isNilOrPlaceholder(currencyCode)
  ) {
    return NO_VALUE_PLACEHOLDER;
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(value);
};

export const formatCount = (value) => {
  if (isNilOrPlaceholder(value) || !isNumber(value)) {
    return NO_VALUE_PLACEHOLDER;
  }

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(value);
};

export const roundSave = (value, numberOfDecimals) => {
  if (!value || !isNumber(value) || !numberOfDecimals) {
    return NO_VALUE_PLACEHOLDER;
  }
  return round(value, numberOfDecimals);
};

export const getYAxisMaxRange = (features, key, multiplier) =>
  Math.max(...features.map((feature) => feature[key])) * multiplier;
export const getYAxisMinRange = (features, key, multiplier) =>
  Math.min(...features.map((feature) => feature[key])) / multiplier;

export const formatUnit = (value, unit) => {
  if (isNilOrPlaceholder(value)) {
    return NO_VALUE_PLACEHOLDER;
  }

  return `${value}${unit}`;
};

export const emptyValueFormatter = (value) => value || NO_VALUE_PLACEHOLDER;

export const formatCountAndUnit = (value, unit) =>
  `${formatUnit(formatCount(value), unit)}`;

export const convertTextLinks = (text) => {
  const urlRegexWithHttpPrefix = /^https?:\/\/[^\s]+/g;
  const urlRegexWithWwwPrefix = /^www.[^\s]+/g;

  const shortenLongString = (urlString) =>
    urlString.length > 80 ? `${urlString.slice(0, 80)}...` : urlString;

  const replaceLinks = (textSection) =>
    textSection
      .replace(
        urlRegexWithWwwPrefix,
        (url) =>
          `<a target="_blank" href="//${url}">${shortenLongString(url)}</a>`,
      )
      .replace(
        urlRegexWithHttpPrefix,
        (url) =>
          `<a target="_blank" href="${url}">${shortenLongString(url)}</a>`,
      );

  return DOMPurify.sanitize(text)
    .replace(/\n/g, '\n ')
    .split(' ')
    .map(replaceLinks)
    .join(' ')
    .replace(/\n /g, '\n');
};

export const valueOrPlaceholder = (value) => value || NO_VALUE_PLACEHOLDER;

export const formatDate = (dateString) => {
  if (isNilOrPlaceholder(dateString) || !dayjs(dateString).isValid()) {
    return NO_VALUE_PLACEHOLDER;
  }
  return dayjs(dateString).format('DD.MM.YYYY');
};

export const formatToSqMeter = (value) => formatUnit(value, '/m²');

export const sortAlphabetically = (property) => (a, b) => {
  if (!b?.[property]) {
    return -1;
  }
  if (!a?.[property]) {
    return 1;
  }
  return toString(a[property]).localeCompare(toString(b[property]));
};

export const sortByDate = (property) => (a, b) => {
  if (!b?.[property] || !dayjs(b?.[property]).isValid()) {
    return -1;
  }
  if (!a?.[property] || !dayjs(a?.[property]).isValid()) {
    return 1;
  }
  return dayjs(a[property]).isAfter(dayjs(b[property])) ? 1 : -1;
};

export const sortNumber = (property) => (a, b) => {
  if (!b?.[property] || !is(Number, b?.[property])) {
    return -1;
  }
  if (!a?.[property] || !is(Number, a?.[property])) {
    return 1;
  }
  return a[property] - b[property];
};

export const isNilOrEmpty = either(isEmpty, isNil);

export const rejectNilOrEmpty = (obj) => reject(isNilOrEmpty)(obj);

export const isProduction = window.location.origin.endsWith(
  '//app.chaosarchitects.com',
);

export const snakeToCamel = (str) => {
  if (!str) {
    return '';
  }

  return str
    .replace(/\s+/g, '')
    .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
};
